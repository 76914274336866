import {subMonths, format} from "date-fns";

export default [
  {
    header: 'Inicio',
    icon: 'HomeIcon',
    route: 'home',
  },
  {
    title:'Vectores',
    header: 'Vectores',
    icon: 'AlertIcon',
    children: [
      {
        title: 'Estadística Vectores',
        route: {
          name: 'vector-stats-list',
        },
        icon: 'FileTextIcon',
      },
    ],
  },
]
